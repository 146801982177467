import React, { useContext, useState } from "react";
import arrow from "../../../Images/arrow.png";
import searchFilter from "../../../Images/search-filter.png";
import { sortBasedOnKey } from "../../../customFunctions/sortingFunction";
import { makePosition } from "../../../customFunctions/makePosition";
import { DataContext } from "../../../Context/DataProvider";
import searchBlue from "../../../Images/search-blue.png";
import { Tooltip as ReactTooltip } from "react-tooltip";


const Result = ({ history, setPlayerHistory }) => {
  const [toggleCoachSort, setToggleCoachSort] = useState(true);
  const [toggleTeamSort, setToggleTeamSort] = useState(true);
  const [toggleAgeSort, setToggleAgeSort] = useState(true);
  const [togglePositionSort, setTogglePositionSort] = useState(true);
  const [toggleGamesSort, setToggleGamesSort] = useState(true);
  const [toggleLeagueSort, setToggleLeagueSort] = useState(true);
  const [toggleSeasonSort, setToggleSeasonSort] = useState(true);
  const [toggleGSort, setToggleGSort] = useState(true);
  const [toggleGASort, setToggleGASort] = useState(true);
  const [toggleGDSort, setToggleGDSort] = useState(true);
  const [toggleStartSort, setToggleStartSort] = useState(true);
  const [toggleEndSort, setToggleEndSort] = useState(true);
  const [toggleImpSort, setToggleImpSort] = useState(true);
  const [togglePPTSSort, setTogglePPTSSort] = useState(true);
  const [togglePTSSort, setTogglePTSSort] = useState(true);
  const [toggleDPTSSort, setToggleDPTSSort] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { base_url, token, user, coachShortListData, setCoachShortListData } =
    useContext(DataContext);

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(history?.length / ITEMS_PER_PAGE);

  // Get the current page's data
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = history?.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleAddOrRemove = (coach) => {
    console.log(coach);
    if (!checkSaveCoach(coachShortListData, coach?.coach)) {
      fetch(`${base_url}/api/v1/coach/shortlist`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user?.id,
          coach_id: coach?.id,
          name: coach?.coach,
          team: coach?.team,
          delta: coach?.delta?coach.delta:"0",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json?.error?.name[0]==="The name has already been taken.") {
            console.log("coach already taken")
          }else{
            setCoachShortListData([...coachShortListData, json]);
          }
          
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } else {
      const targetedCoach = checkSaveCoach(coachShortListData, coach?.coach);
      console.log(targetedCoach);
      fetch(`${base_url}/api/v1/coach/shortlist/${targetedCoach?.id}`, {
        method: "DELETE",
        body: JSON.stringify({
          user_id: user?.id,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            const remainingCoach = coachShortListData?.filter((obj) => obj?.id !== targetedCoach?.id);
            
            setCoachShortListData(remainingCoach);
          }
        })
        .catch((error)=>{
          alert("server error")
        })
      
    }
  };

  function checkSaveCoach(arr, searchName) {
    console.log(arr);
    const foundObject = arr.find((item) => item?.name === searchName);
    console.log(foundObject);

    return foundObject;
    
  }
  function getQULText(value) {
    if (value >= 0 && value < 65) {
      return "Lower-level";
    } else if (value >= 65 && value < 70) {
      return "Competitive";
    } else if (value >= 70 && value < 75) {
      return "Strong";
    } else if (value >= 75 && value < 83) {
      return "Premier";
    } else if (value >= 83 && value <= 100) {
      return "Elite";
    } else {
      return "Invalid value";
    }
  }

  function getBOTText(value) {
    if (value < -15) {
      return "Underdogs";
    } else if (value >= -15 && value < -10) {
      return "Relegation";
    } else if (value >= -10 && value < -4) {
      return "Lower-Table";
    } else if (value >= -4 && value <= 4) {
      return "Competitive";
    } else if (value > 4 && value <= 12) {
      return "Strong";
    } else if (value > 12 && value <= 20) {
      return "Contenders";
    } else if (value > 20) {
      return "Champions";
    } else {
      return "Invalid value";
    }
  }

  function getLQLText(value) {
    if (value >= 0 && value < 25) {
      return "Amateur";
    } else if (value >= 25 && value < 40) {
      return "Lower-tier";
    } else if (value >= 40 && value < 55) {
      return "Competitive";
    } else if (value >= 55 && value < 60) {
      return "Strong";
    } else if (value >= 60) {
      return "Elite";
    } else {
      return "Invalid value";
    }
  }
  
  
  

  return (
    <div className=" metric_main_content bg-white overflow-x-scroll">
      <div className="px-6 py-4 border-b">
        <h3 className="font-semibold 2xl:text-base">Results</h3>
      </div>
      <div className="overflow-x-auto pt-4 px-6 font-semibold metric-table">
        <table className="table w-full">
          {/* head */}
          <thead className="text-neutral-content">
            <tr>
              <th className="w-[16%]"> </th>
              <th className="w-[15%]"> </th>
              <th className="w-[2%]"> </th>
              <th className="w-[4%]"> </th> 
              <th className="w-[4%]"> </th>
              {/* <th>
                <div className="text-center table-top-header-empty"></div>
                <p
                  onClick={() => {
                    sortBasedOnKey(
                      "league_code",
                      toggleLeagueSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleLeagueSort(!toggleLeagueSort);
                  }}
                  className="w-[64px] min-[1920px]:w-[45px] cursor-pointer text-center"
                >
                  LEAGUE
                </p>
              </th> */}

              <th className="w-[13%]">
                <div className="text-center">World Club Rating</div>
              </th>
              <th className="w-[13%]">
                <div className="text-center">Points</div>
              </th>
              <th className="w-[20%]">
                <div className="text-center"> PERFORMANCE </div>
              </th>
            </tr>
          </thead>
          <tbody className="mt-5">
          <tr className="text-neutral-content">
            <td>
                <div
                    onClick={() => {
                        sortBasedOnKey(
                            "coach",
                            toggleCoachSort,
                            history,
                            setPlayerHistory
                        );
                        setToggleCoachSort(!toggleCoachSort);
                    }}
                    className="text-start  cursor-pointer"
                >
                    Coach
                </div>
            </td>
            <td>
                <div
                    onClick={() => {
                        sortBasedOnKey(
                            "team",
                            toggleTeamSort,
                            history,
                            setPlayerHistory
                        );
                        setToggleTeamSort(!toggleTeamSort);
                    }}
                    className="text-start cursor-pointer"
                >
                    Team
                </div>
            </td>
            <td>
                <p
                    onClick={() => {
                        sortBasedOnKey("age", toggleAgeSort, history, setPlayerHistory);
                        setToggleAgeSort(!toggleAgeSort);
                    }}
                    className="text-center cursor-pointer"
                >
                    AGE
                </p>
            </td>
            <td>
                <p
                    onClick={() => {
                        sortBasedOnKey(
                            "games",
                            toggleGamesSort,
                            history,
                            setPlayerHistory
                        );
                        setToggleGamesSort(!toggleGamesSort);
                    }}
                    className="text-center cursor-pointer"
                >
                    GAMES
                </p>
            </td>
            <td>
                <p
                    onClick={() => {
                        sortBasedOnKey(
                            "season",
                            toggleSeasonSort,
                            history,
                            setPlayerHistory
                        );
                        setToggleSeasonSort(!toggleSeasonSort);
                    }}
                    className="text-center cursor-pointer"
                >
                    SEASON
                </p>
            </td>
            <td>
                <div className=" grid grid-cols-3 mx-4">
                    <h6
                        onClick={() => {
                            sortBasedOnKey(
                                "off",
                                toggleGSort,
                                history,
                                setPlayerHistory
                            );
                            setToggleGSort(!toggleGSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        START
                    </h6>
                    <h6
                        onClick={() => {
                            sortBasedOnKey(
                                "def",
                                toggleGASort,
                                history,
                                setPlayerHistory
                            );
                            setToggleGASort(!toggleGASort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        END
                    </h6>
                    <h6
                        onClick={() => {
                            sortBasedOnKey(
                                "total",
                                toggleGDSort,
                                history,
                                setPlayerHistory
                            );
                            setToggleGDSort(!toggleGDSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        {/* DIFF */}
                    </h6>
                </div>
            </td>
            <td>
                <div className=" grid grid-cols-3 mx-4">
                    <h6
                        data-tooltip-id="ppts"
                        onClick={() => {
                            sortBasedOnKey(
                                "pPTS",
                                togglePPTSSort,
                                history,
                                setPlayerHistory
                            );
                            setTogglePPTSSort(!togglePPTSSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        pPTS
                    </h6>
                    <ReactTooltip
                        id="ppts"
                        variant="info"
                        place="top"
                        opacity={1}
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <div className="px-3 py-1 border rounded-lg bg-white text-neutral-content">
                            Predicted Points Per Year 
                        </div>
                    </ReactTooltip>
                    <h6 
                        data-tooltip-id="pts"
                        onClick={() => {
                            sortBasedOnKey(
                                "pts",
                                togglePTSSort,
                                history,
                                setPlayerHistory
                            );
                            setTogglePTSSort(!togglePTSSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        PTS
                    </h6>
                    <ReactTooltip
                        id="pts"
                        variant="info"
                        place="top"
                        opacity={1}
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <div className="px-3 py-1 border rounded-lg bg-white text-neutral-content">
                            Points Per Year 
                        </div>
                    </ReactTooltip>
                    <h6
                        onClick={() => {
                            sortBasedOnKey(
                                "dPTS",
                                toggleDPTSSort,
                                history,
                                setPlayerHistory
                            );
                            setToggleDPTSSort(!toggleDPTSSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        {/* dPTS */}
                    </h6>
                </div>
            </td>
            <td>
                <div className="grid grid-cols-3 mx-4 ">
                    <h6 
                        data-tooltip-id="lql"
                        onClick={() => {
                            sortBasedOnKey(
                                "rJ",
                                toggleStartSort,
                                history,
                                setPlayerHistory
                            );
                            setToggleStartSort(!toggleStartSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        LQL
                    </h6>
                    <ReactTooltip
                        id="lql"
                        variant="info"
                        place="top"
                        opacity={1}
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <div className="px-3 py-1 border rounded-lg bg-white text-neutral-content">
                            League Quality
                        </div>
                    </ReactTooltip>
                    <h6 
                        data-tooltip-id="qul"
                        onClick={() => {
                            sortBasedOnKey(
                                "rN",
                                toggleEndSort,
                                history,
                                setPlayerHistory
                            );
                            setToggleEndSort(!toggleEndSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        QUL
                    </h6>
                    <ReactTooltip
                        id="qul"
                        variant="info"
                        place="top"
                        opacity={1}
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <div className="px-3 py-1 border rounded-lg bg-white text-neutral-content">
                            Team Quality 

                        </div>
                    </ReactTooltip>
                    <h6
                        data-tooltip-id="qro"
                        onClick={() => {
                            sortBasedOnKey(
                                "delta",
                                toggleImpSort,
                                history,
                                setPlayerHistory
                            );
                            setToggleImpSort(!toggleImpSort);
                        }}
                        className="text-center  cursor-pointer"
                    >
                        BOT
                    </h6>
                    <ReactTooltip
                        id="qro"
                        variant="info"
                        place="top"
                        opacity={1}
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <div className="px-3 py-1 border rounded-lg bg-white text-neutral-content">
                            Top to Bottom difference
                        </div>
                    </ReactTooltip>
                </div>
            </td>
          </tr>

            {currentItems?.map((player, index) => (
              <tr key={index} className="border-b">
                <td>
                  <div className="font-semibold flex gap-2 items-center ">
                  {checkSaveCoach(coachShortListData, player?.coach) ? (
                      <img
                        className="cursor-pointer"
                        onClick={() => handleAddOrRemove(player)}
                        src={searchBlue}
                        alt="icon"
                      />
                    ) : (
                      <img
                        className="cursor-pointer"
                        onClick={() => handleAddOrRemove(player)}
                        src={searchFilter}
                        alt="icon"
                      />
                    )}
                    <h4 className="whitespace-pre-wrap" >{player?.coach}</h4>
                  </div>
                </td>
                <td>
                  <p className="font-medium whitespace-pre-wrap text-neutral-content ">
                    {player?.team}
                  </p>
                </td>

                <td className="font-medium table-text-content text-center">
                  {player?.age > 0 ? player?.age : "-"}
                </td>
                <td className="font-medium table-text-content text-center text-neutral-content">
                  {player?.num_matches}
                </td>
                <td className="font-medium table-text-content text-center text-neutral-content">
                  {player?.league_start_year}
                </td>
                {/* <td className="font-medium table-text-content text-center text-neutral-content">
                  {player?.league}
                </td> */}

                <td >
                    <div className="px-4 border-x ">
                        <div className="flex justify-around font-semibold text-sm">
                            <div className="border h-8 w-14 rounded flex justify-center items-center bg-gray-100">
                                {/* {player?.off ? Number(player.off).toFixed(2) : "0.00"} */}
                                {player?.initial_WCR?.toFixed(0)}
                            </div>
                            <div className="border h-8 w-14 rounded flex justify-center items-center bg-gray-100">
                                {/* {player?.def ? Number(player.def).toFixed(2) : "0.00"} */}
                                {player?.final_WCR?.toFixed(0)}
                            </div>
                            <div
                                className={`border h-8 w-14 rounded flex justify-center items-center  ${
                                Number(player?.IMP?.toFixed(0)) < 0
                                    ? "text-[#fa4265] bg-[#fa426514]"
                                    : Number(player?.IMP?.toFixed(0)).toFixed(2) > 0.0
                                    ? "text-[#02b059] bg-[#02b05914]"
                                    : " bg-gray-100"
                                } `}
                            >
                                
                                {player?.IMP < 0 && player?.IMP > -0.5 ? 0 : player?.IMP.toFixed(0)}%
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-[33px] ml-[-2px] flex justify-center items-center">
                      <div className="w-px h-6 border-r"></div>
                    </div> */}
                    
                  
                </td>
                <td >
                    <div className="px-4 border-r ">
                        <div className="flex justify-around font-semibold text-sm">
                            <div className="border h-8 w-14 rounded flex justify-center items-center bg-gray-100">
                                {/* {player?.off ? Number(player.off).toFixed(2) : "0.00"} */}
                                {player?.league_predicted_points_per_game?.toFixed(1)}
                            </div>
                            <div className="border h-8 w-14 rounded flex justify-center items-center bg-gray-100">
                                {/* {player?.def ? Number(player.def).toFixed(2) : "0.00"} */}
                                {player?.league_points_per_game?.toFixed(1)}
                            </div>
                            <div
                                className={`border h-8 w-14 rounded flex justify-center items-center ${
                                Number(player?.league_points_diff?.toFixed(1)) < 0
                                    ? "text-[#fa4265] bg-[#fa426514]"
                                    : Number(player?.league_points_diff?.toFixed(1)).toFixed(2) > 0.0
                                    ? "text-[#02b059] bg-[#02b05914]"
                                    : " bg-gray-100"
                                } `}
                            >
                                {/* {player?.total ? +Number(player.total).toFixed(2) : "0.00"} */}
                                {player?.league_points_diff?.toFixed(1)}
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-[33px] ml-[-2px] flex justify-center items-center">
                      <div className="w-px h-6 border-r"></div>
                    </div> */}
                    
                  
                </td>
                <td >
                    <div className="px-4 border-r ">
                        <div className="grid grid-cols-3  font-semibold text-sm">
                            <div className=" text-center">
                                {/* {player?.off ? Number(player.off).toFixed(2) : "0.00"} */}
                                {/* {player?.LQL?.toFixed(1)} */}
                                {getLQLText(player?.LQL?.toFixed(1))}
                            </div>
                            <div className=" text-center">
                                {/* {player?.def ? Number(player.def).toFixed(2) : "0.00"} */}
                                
                                {getQULText(player?.QUL?.toFixed(1))}
                            </div>
                            <div className=" text-center">
                                {/* {player?.total ? +Number(player.total).toFixed(2) : "0.00"} */}
                                {getBOTText(player?.BOT?.toFixed(1))}
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-[33px] ml-[-2px] flex justify-center items-center">
                      <div className="w-px h-6 border-r"></div>
                    </div> */}
                    
                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Footer */}
      <div className="mt-4 mb-2 text-center">
        <div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              className={`w-8 mx-1 h-8 text-neutral-content  border rounded-md ${
                currentPage === index + 1 && "active-page"
              } `}
              key={index}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Result;
